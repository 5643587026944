<template>
  <PreparingResult v-if="loading" @finish-loading="turnOffLoading"/>
  <Content v-else>
    <ShareLinkModal :showing-modal="dialog"
                    :link="link"
                    @show-modal="closeShareLinkModal"
                    @copied-link="copiedLink"
    />
    <DetailsStrengthsModal
      v-if="detailsElement"
      :showing-modal="showModal"
      @show-modal="closeModal($event)"
      :strength-info="detailsElement"/>
    <div class="self_answer">
      <div class="сongratulation-completed-survey">
        <div class="content-text">
          <div class="result-button-basic" @click="myResultResult"
               :class="{'result-change-button': myResult}">
            <span>YOUR STRENGTHS</span>
          </div>
          <div class="result-button-basic" @click="crowdResultResult"
               :class="{'result-change-button': !myResult}">
            <span>SUPPORTEES ({{invitationResult.length}})</span>
          </div>
        </div>
        <div class="questionnaire-results"
             v-show="myResult"
             v-if="!strengthConversionScoreArray[0].resultIsNotReady">
           <HavenBeenChosen :self-result="true"/>
        </div>
        <div class="questionnaire-results" v-show="myResult" v-else>
          <div class="title-expand-invitation">
            <div class="title-you-result">YOU</div>
            <div class="title-crowd-result">ALL SUPPORTERS ({{respondentsCount}})</div>
          </div>
          <div v-for="(item, index) in strengthConversionScoreArray"
               :key="item.id" class="general-block-with-strengths-bars">
            <div class="block-result" @click="showExplainModal(item.name)">
              <StrengthsBarResult
                :width="item.percentage"
                :strengthName="item.name"
                :color="item.color"
                :filter="item.filter"
                :result-is-not-ready="item.resultIsNotReady"
                :is-crowd-result="false"
              />
            </div>
            <div class="block-result"
                 @click="showExplainModal(strengthConversionScoreForOtherArray[index].name)">
              <StrengthsBarResult
                :width="strengthConversionScoreForOtherArray[index].percentage"
                :strengthName="strengthConversionScoreForOtherArray[index].name"
                :color="strengthConversionScoreForOtherArray[index].color"
                :filter="strengthConversionScoreForOtherArray[index].filter"
                :resultIsNotReady="strengthConversionScoreForOtherArray[index]
                          .resultIsNotReady"
                :is-crowd-result="true"
              />
            </div>
          </div>
        </div>
        <div class="questionnaire-results"
             v-show="!myResult"
             v-if="invitationResult.length === 0">
          <HavenBeenChosen :self-result="false"/>
        </div>
        <div class="questionnaire-results-supportees question-results-addaptive"
             v-show="!myResult" v-else>
          <div>
            <ExpandInvitationResult
                @open-strength-info="showExplainModal($event)"
                :open-the-supportees-result="compareOpenSupporteesResult()"
                @refresh-supporters-result="refreshSupportersResultOpen"
                :latest-news="getProfile.token ? true : getProfile.latestNews"
                :invitation-result="invitationResult"/>
          </div>
          <div v-if="!getProfile.token"
            class="email-u2-send-result-block">
            <EmailU2SendResult
              :error="errorSaveNotification"
              v-if="!getProfile.latestNews && !getProfile.token"
              @refresh-error="refreshNotifyError"
              @confirm-email="sendSupportersNotification"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!getProfile.token && myResult" class="space-class-second"></div>
    <div class="bottom-part-of-site" v-if="myResult">
      <ActionButton
        v-if="!strengthConversionScoreArray[0].resultIsNotReady"
        :text="'FIND MY STRENGTHS'"
        @click-button="startQuiz"/>
      <ActionButton
        v-else-if="!getProfile.token"
        :text="'NEXT'"
        @click-button="trustPage"/>
      <SecondaryButton
        v-else
        :text="buttonCopyLinkText"
        @click-button="copyInputData"/>
    </div>
  </Content>
</template>

<script>
import helpFunction from '@helpers/helpFunction';
import PreparingResult from '@innerworks_ds/innerworks-storybook-main/src/stories/PreparingResult/PreparingResult.vue';
import StrengthsBarResult from '@innerworks_ds/innerworks-storybook-main/src/stories/StrengthsBarResult/StrengthsBarResult.vue';
import emptySupporters from '@assets/supportees-not-result.svg';
import DetailsStrengthsModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/DetailStrengthsModal/DetailsStrengthsModal.vue';
import HavenBeenChosen from '@innerworks_ds/innerworks-storybook-main/src/stories/HavenBeenChosen/HavenBeenChosen.vue';
import { mapGetters } from 'vuex';
import ExpandInvitationResult from '@innerworks_ds/innerworks-storybook-main/src/stories/ExpandInvitationResult/ExpandInvitationResult.vue';
import ActionButton from '@innerworks_ds/innerworks-storybook-main/src/stories/Button/ActionButton.vue';
import SecondaryButton from '@innerworks_ds/innerworks-storybook-main/src/stories/SecondaryButton/SecondaryButton.vue';
import EmailU2SendResult from '@innerworks_ds/innerworks-storybook-main/src/stories/EmailU2SendResult/EmailU2SendResult.vue';
import ShareLinkModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/ShareLinkModal/ShareLinkModal.vue';
import Vue from 'vue';
import differentsServices from '@helpers/differentsServices';

export default {
  components: {
    ActionButton,
    ExpandInvitationResult,
    HavenBeenChosen,
    DetailsStrengthsModal,
    StrengthsBarResult,
    PreparingResult,
    EmailU2SendResult,
    SecondaryButton,
    ShareLinkModal,
  },
  name: 'Report',
  data: () => ({
    strengthConversionScoreObject: {},
    strengthConversionScoreForOther: {},
    respondentsCount: 0,
    emptySupporters,
    showModal: false,
    detailsElement: null,
    strengthDescription: [],
    link: '',
    buttonCopyLinkText: 'INVITE SUPPORTERS',
    linkCopied: false,
    isOthersAmount: false,
    invitationResult: [],
    loading: true,
    dialog: false,
    errorSaveNotification: null,
    dialogDeleteAccount: false,
  }),
  watch: {
    // eslint-disable-next-line func-names
    'getProfile.token': function (newValue) {
      if (newValue) {
        this.fetchPersonalityTypeReport();
      }
    },
    selectedOptions(value) {
      this.otherAmountCalculate(this.otherLastResult, value);
    },
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      getSupporteesResultUserId: 'auth/getSupporteesResultUserId',
      getPersonalityTest: 'invitation/getPersonalityTest',
      myResult: 'behaviorComponents/isNotCrowdTabResultPage',
    }),
    showButtons() {
      return (this.getProfile.registeredFromCSV && this.showButtonAskContactsForInvitation)
        || !this.getProfile.registeredFromCSV;
    },
    userRegisteredFromCSV() {
      return this.getProfile.registeredFromCSV;
    },
    isAuthUser() {
      return this.getProfile.token;
    },
    strengthConversionScoreArray() {
      if (Object.keys(this.strengthConversionScoreObject).length !== 0) {
        return helpFunction.strengthConversionScore(this.strengthConversionScoreObject, '#320959', 'invert(61%) sepia(6%) saturate(2376%) hue-rotate(227deg) brightness(88%) contrast(90%)', this.respondentsCount, true, true);
      }

      return [{
        resultIsNotReady: false,
      }];
    },
    strengthConversionScoreForOtherArray() {
      return helpFunction.strengthConversionScore(this.strengthConversionScoreForOther, '#FF5151', 'invert(93%) sepia(63%) saturate(3313%) hue-rotate(295deg) brightness(104%) contrast(93%)', this.respondentsCount, false, true);
    },
  },
  created() {
    this.$store.dispatch('showComponent/turnOffHeader');
    this.fetchPersonalityTypeReport();
  },
  updated() {
    if (!this.loading) {
      this.$store.dispatch('showComponent/showHeaderMain');
    } else {
      this.$store.dispatch('showComponent/turnOffHeader');
    }
    const notifyBlock = document.querySelector('.email-u2-send-result');
    const questionnaireSupportess = document.querySelector('.questionnaire-results-supportees');
    if (questionnaireSupportess) {
      questionnaireSupportess.style.setProperty('--s', `${45 + 75 + 35 + 50}px`);
      if (notifyBlock) {
        const styles = notifyBlock.getBoundingClientRect();
        document.querySelector('.questionnaire-results-supportees').style.setProperty('--h', `${styles.height + 25}px`);
      }
    }
  },
  methods: {
    closeDeleteUserThankingWindowModal() {
      this.dialogDeleteAccount = false;
    },
    refreshSupportersResultOpen() {
      if (!(this.getProfile.token
        && this.getProfile.email === this.getSupporteesResultUserId.email)) {
        this.$store.dispatch('behaviorComponents/changeCrowdTab', true);
      }
      this.$store.dispatch('auth/setSupporteesResultUserId', {});
    },
    closeShareLinkModal(value) {
      this.dialog = value;
    },
    copiedLink() {
      Vue.notify({
        type: 'success',
        title: 'Link copied',
      });
    },
    compareOpenSupporteesResult() {
      return this.getProfile.token ? this.getSupporteesResultUserId.userId : '';
    },
    async prepareDataForRequestRegister(checkboxInform, mail) {
      return {
        name: this.getProfile.name ? this.getProfile.name : '',
        surname: '',
        youEmail: mail,
        questionId: this.getProfile.completedQuestionnaires
          ? this.getProfile.completedQuestionnaires[0] : null,
        uniqueId: localStorage.getItem('uniqueId'),
        fingerPrint: differentsServices.getClientData(),
        checkboxInform,
        registerWithActivateMessage: true,
      };
    },
    async sendSupportersNotification(data) {
      const userIds = [];
      // eslint-disable-next-line
      for (const emailKey of this.invitationResult) {
        userIds.push(emailKey.userId);
      }

      const formData = {
        email: data.mail,
        u2Ids: userIds,
        preRegisterData: await this.prepareDataForRequestRegister(true, data.mail),
      };

      this.$api.user.saveNotificationCrowdEmails(formData).then(() => {
        this.$store.dispatch('auth/setNotificationCrowdEmail', { latestNews: true, email: data.mail, notificationCrowdSentEmail: true });
        this.$store.dispatch('behaviorComponents/changeCrowdTab', true);
      }).catch((e) => {
        this.errorSaveNotification = e.response.data.message;
        if (e.response.data.errorFields && e.response.data.errorFields.isRegistered) {
          this.$store.dispatch('auth/setSupporteesResultUserId', {
            userId: this.invitationResult[0].userId,
            email: data.mail,
          });
        }
      });
    },
    refreshNotifyError() {
      this.errorSaveNotification = null;
    },
    closeModal(value) {
      this.showModal = value;
      this.detailsElement = null;
    },
    getStrengthsContent(name) {
      const result = this.strengthDescription.find(element => element.name === name);
      if (result) this.detailsElement = result;
      return undefined;
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('main');
    },
    startQuiz() {
      this.$store.dispatch('invitation/setInvitationData', {});
      if (this.getProfile.token && !this.getProfile.name) {
        this.$router.push({
          name: 'ask-name',
        });
      } else if (this.getProfile.token) {
        this.$router.push({
          name: 'questionnaire',
        });
      } else {
        this.$router.push({
          name: 'main',
          params: {
            slide: 3,
          },
        });
      }
    },
    trustPage() {
      this.$router.push({
        name: 'trust',
      });
    },
    copyInputData() {
      this.dialog = true;
    },
    showExplainModal(item) {
      this.getStrengthsContent(item);
      this.showModal = true;
    },
    setChosenGroupCharacteristic(event, myResultsSc, othersResultsSc, chooseOtherResult) {
      helpFunction.setChosenGroupCharacteristic(event, myResultsSc, othersResultsSc,
        chooseOtherResult);
    },
    fetchPersonalityTypeReport() {
      this.loading = true;
      this.$api.strengthDescription.getStrengthDescription()
        .then((x) => {
          this.strengthDescription = x.strengthContents;
        });
      this.$store.dispatch('invitation/setInvitationData', {});
      if (!this.isAuthUser) {
        const uniqueId = localStorage.getItem('uniqueId');
        this.$api.personalityTypeReport.fetchPersonalityTypeReportNewFreeVersion(uniqueId)
          .then((res) => {
            this.strengthConversionScoreObject = res.strengthConversionScore;
            this.strengthConversionScoreForOther = res.strengthConversionScoreForOther;
            this.respondentsCount = res.othersAmount;
            this.myResultsScoreData = res.selfResult;
            this.invitationResult = res.invitations;
          }).catch(() => {
            this.$store.dispatch('showComponent/gotToStepInitial');
          });
      } else {
        this.$api.personalityTypeReport.fetchPersonalityTypeReport().then((res) => {
          this.data = [];
          this.strengthConversionScoreObject = res.strengthConversionScore;
          this.strengthConversionScoreForOther = res.strengthConversionScoreForOther;
          this.respondentsCount = res.othersAmount;
          this.myResultsScoreData = res.selfResult;
          this.other = res.selfResult;
          this.otherLastResult = res;
          this.invitationResult = res.invitations;

          this.groupOptions = res.groupForInLink;
          this.link = `${window.location.protocol}//${window.location.host}${res.invitationLink}`;
          this.$emit('share-link', `${window.location.protocol}//${window.location.host}${res.invitationLink}`);
          this.$emit('respondents-count', res.othersAmount);
        }).catch(() => {
          this.$store.dispatch('showComponent/gotToStepInitial');
        });
      }
    },
    setShowReportModal(value) {
      this.showReportModal = value;
    },
    turnOffLoading() {
      this.loading = false;
    },
    myResultResult() {
      this.$store.dispatch('behaviorComponents/changeCrowdTab', true);
    },
    crowdResultResult() {
      this.$store.dispatch('behaviorComponents/changeCrowdTab', false);
    },
  },
};
</script>
<style lang="scss" scoped>

.button_expand {
  background-color: #ffffff;
  color: #a158d9;
  border-radius: 40px;
}

.v-expansion-panel-header {
  width: 100%;
  border-radius: 12px;
  padding: 13px;
}

.sub-title {
  font-size: 35px;
}

.you-strengths {
  width: 50%;
}

.strengths-result {
  width: 50%;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.v-application .flex-row-reverse {
  flex-direction: row !important;
}

.v-expansion-panel:before {
  box-shadow: none;
  padding: 0%;
  background: white;
}

.general-block-with-strengths-bars {
  display: flex;
  flex-direction: row;
}


.block-result {
  width: 50%;
  cursor: pointer;
  margin-bottom: 5px;
}

.strengths-result {
  width: 100%;
}

.title-expand-invitation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.self_answer {
  font-family: $newDefaultFont;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .content-text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #7811C9;
    flex-direction: row;

    .result-change-button {
      border-bottom: 3px solid #7811C9;
      display: block;
    }

    .result-button-basic {
      width: 50%;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 15px 10px 13px;
    }
  }

  .сongratulation-completed-survey {
    text-align: center;
    margin-top: 40px;
  }

  .questionnaire-results {
    display: flex;
    width: 100%;
    margin-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    flex-direction: column;
  }
}

@media (max-height: 700px) {
  .footer-menu-question {
    margin-bottom: 0 !important;
  }
  .space-class {
    height: 138px !important;
  }
  .button-custom-secondary {
    height: 67px !important;
  }
}

.footer-menu-question {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-right: 25px;
  padding-left: 25px;
  margin-bottom: 97px;
  align-items: center;

  .logout {
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #7811C9;
    border-radius: 1px;
    cursor: pointer;
  }
}

.title-you-result {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: #320959;
  margin-bottom: 20px;
  text-align: center;
  width: 50%;
}

.title-crowd-result {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: #FF5151;
  margin-bottom: 20px;
  text-align: center;
  width: 50%;
}
.questionnaire-results-supportees {
  margin-top: 35px;
  margin-left: 25px;
  margin-right: 25px;
  overflow-y: auto;
}

.question-results-addaptive {
  height: calc(100vh - var(--h, 0px) - var(--s));
  margin-bottom: var(--h, 0px);
  min-height: calc(400px - var(--h, 0px));
}

.bottom-part-of-site {
  position: fixed;
  bottom: 0;
  max-width: 480px;
  width: 100%;
}

@media (max-height: 680px) {
  .bottom-part-of-site {
    position: static;
  }
  .сongratulation-completed-survey {
    margin-bottom: 80px;
  }
}

.space-class {
  height: 155px;
}

.space-class-second {
  height: 102px;
}

@media (max-height: 820px) {
  .title-crowd {
    margin-top: 0 !important;
  }
}
.title-expand-invitation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.title-you-result {
  margin-bottom: 21px;
}

.title-crowd-result {
  margin-bottom: 21px;
}

.email-u2-send-result-block {
  position: absolute;
  left: 0;
  bottom: 0;
}

.button-custom-secondary {
  position: absolute;
  height: 90px;
}
</style>
